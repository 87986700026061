body {
  font-family: Montserrat, sans-serif;
}

.header-hero {
  background-color: #fff;
  height: 100vh;
  margin-bottom: 86px;
}

.header-hero__title {
  color: #0000;
  background-image: linear-gradient(-190deg, #997cd1, #8369b5);
  background-clip: text;
  font-weight: 800;
}

.header-hero__logo {
  width: 150px;
  margin-bottom: 20px;
}

.header-hero__links {
  margin-bottom: 20px;
}

.header-hero__links a {
  color: #997cd1;
  font-size: 18px;
}

.header-hero__text {
  color: #997cd1;
  font-family: IBM Plex Mono, monospace;
}

.podcast__badge {
  text-transform: uppercase;
  border: 1px solid #fff;
  border-radius: 2px;
  margin-bottom: 20px;
  padding: 0 2px;
  font-size: 9px;
  display: inline-block;
}

.podcast__container {
  background-image: linear-gradient(-190deg, #6262ed, #3939ad);
}

.podcast-behind {
  height: 100vh;
  padding: 0 0 0 15px;
}

.podcast-behind__text {
  color: #fff;
}

.podcast-behind__text h1 {
  margin: 0;
  line-height: 1;
}

.podcast-behind__text p {
  font-family: IBM Plex Mono, monospace;
}

.podcast__feed-link {
  color: #fff;
  font-size: 26px;
  font-weight: bold;
}

.podcast__feed-link:hover {
  color: #fff;
}

.podcast-covers {
  flex-wrap: wrap;
  padding: 60px 0;
}

.podcast-cover {
  flex: 22.333%;
  margin: 16px;
}

.podcast-cover__image {
  width: 100%;
}

.podcast-cover__link {
  display: block;
  position: relative;
  overflow: hidden;
}

.podcast-cover__link-text {
  color: #3939ad;
  transition: all .3s;
  position: absolute;
  bottom: 20px;
  left: 6%;
  transform: translateY(50px);
}

@media (width <= 1170px) {
  .podcast-cover__link-text {
    transform: translateY(0);
  }
}

.podcast-cover__link:hover .podcast-cover__link-text {
  transition: all .3s;
  transform: translateY(0);
}

.live__container {
  background-color: #fff;
  height: 100vh;
  margin-bottom: 86px;
}

.live__type {
  color: #3939ad;
}

.live__title {
  color: #3939ad;
  margin: 0;
}

.live__badge {
  text-transform: uppercase;
  color: #3939ad;
  border: 1px solid #3939ad;
  border-radius: 2px;
  margin-bottom: 20px;
  padding: 0 2px;
  font-size: 9px;
  display: inline-block;
}

.live__text p {
  color: #3939ad;
  font-family: IBM Plex Mono, monospace;
}

.footer {
  z-index: -2;
  color: #fff;
  background-image: linear-gradient(-190deg, #997cd1, #7e67ad);
  width: 100%;
  padding: 30px 0;
  position: fixed;
  bottom: 0;
}
/*# sourceMappingURL=index.f430569f.css.map */

body {
  font-family: 'Montserrat', sans-serif;
}

.header-hero {
  height: 100vh;
  background-color: white;
  margin-bottom: 86px;
}

.header-hero__title {
  -webkit-background-clip: text;
  background-clip: text;
  background-image: linear-gradient(-190deg, #997CD1, #8369b5);
  color: transparent;
  font-weight: 800;
}

.header-hero__logo {
  width: 150px;
  margin-bottom: 20px;
}

.header-hero__links {
  margin-bottom: 20px;  
}

.header-hero__links a{
  color: #997CD1;
  font-size: 18px;
}

.header-hero__text {
  font-family: 'IBM Plex Mono', monospace;
  color: #997CD1;
}

.podcast__badge {
  border: 1px solid white;
  font-size: 9px;
  margin-bottom: 20px;
  display: inline-block;
  text-transform: uppercase;
  padding: 0px 2px;
  border-radius: 2px;
}

.podcast__container {
  background-image: linear-gradient(-190deg, rgb(98, 98, 237), rgb(57, 57, 173));
}


.podcast-behind {
  height: 100vh;
  padding: 0px 0px 0px 15px;
}

.podcast-behind__text {
  color: white;
}

.podcast-behind__text h1{
  margin: 0;
  line-height: 1
}

.podcast-behind__text p{
  font-family: 'IBM Plex Mono', monospace;
}

.podcast__feed-link {
  color: white;
  font-weight: bold;
  font-size: 26px;
}

.podcast__feed-link:hover {
  color: white;
}

.podcast-covers {
  flex-wrap: wrap;
  padding: 60px 0;
}

.podcast-cover {
  flex: 22.333%;
  
  margin: 16px;
}

.podcast-cover__image {
  width: 100%;
}

.podcast-cover__link {
  position: relative;
  display: block;
  overflow: hidden;
}

.podcast-cover__link-text {
  position: absolute;
  left: 6%;
  bottom: 20px;
  transform: translateY(50px);
  transition: 300ms;
  color: rgb(57, 57, 173);
}


@media (max-width: 1170px) {
  .podcast-cover__link-text {
    transform: translateY(0px);
  }
}

.podcast-cover__link:hover .podcast-cover__link-text {
  transform: translateY(0px);
  transition: 300ms;
}

.live__container {
  height: 100vh;
  background-color: white;
  margin-bottom: 86px;
}

.live__type {
  color: rgb(57, 57, 173);
}

.live__title {
  margin: 0;
  color: rgb(57, 57, 173);
}

.live__badge {
  border: 1px solid rgb(57, 57, 173);
  font-size: 9px;
  margin-bottom: 20px;
  display: inline-block;
  text-transform: uppercase;
  padding: 0px 2px;
  color: rgb(57, 57, 173);
  border-radius: 2px;
}

.live__text p { 
  font-family: 'IBM Plex Mono', monospace;
  color: rgb(57, 57, 173);
}

.footer {
  position: fixed;
  width: 100%;
  z-index: -2;
  bottom: 0;
  background-image: linear-gradient(-190deg, #997CD1, #7e67ad);
  color: white;
  padding: 30px 0;
}
